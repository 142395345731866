export default {
  data: () => ({
    productEnums: {
      1: "Darzalex",
      2: "Erleada",
      3: "Imbruvica",
      4: "Tremfaya",
      5: "Stelara CD",
      6: "Stelara UC",
    },
  }),
};
