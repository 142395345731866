<template>
  <section class="conOfPatientList conOfListSettings">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="sectionHeaderWithSub">
            HCP
            <router-link class="eachMapLink" :to="{ name: 'settingProducts' }">
              System Settings -
            </router-link>
            <router-link
              class="eachMapLink"
              :to="{
                name: 'settingProductItem',
                params: { id: $route.params.id },
              }"
            >
              {{ productEnums[$route.params.id] }}
            </router-link>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="conOfListItems">
            <div class="d-flex mb-5">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="textBtnRed"
                    text
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add New
                  </v-btn>
                </template>
                <v-card class="conOfPopup">
                  <v-card-title>
                    <span class="text-h5 modalTitle">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text class="conFormSubmit">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            v-model="formItem.name"
                            label="Name"
                            hide-details="auto"
                            required
                            :error-messages="formItemNameErrors"
                            @input="$v.formItem.name.$touch()"
                            @blur="$v.formItem.name.$touch()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="pb-10">
                    <v-spacer></v-spacer>
                    <v-btn class="btnDismissed" tile @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      class="btnPrimaryBink"
                      tile
                      @click="saveItem"
                      :disabled="isLoadingSave"
                      :loading="isLoadingSave"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="400px">
                <v-card class="conConfirmPopup">
                  <v-card-title>
                    <span class="text-h5 modalTitle">Are you sure</span>
                  </v-card-title>
                  <v-card-text class="textConfirm"
                    >Are you sure you want to delete this item?</v-card-text
                  >
                  <v-card-actions class="pb-7">
                    <v-spacer></v-spacer>
                    <v-btn class="btnDismissed" tile @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="btnPrimaryBink"
                      tile
                      @click="deleteItemConfirm"
                      :disabled="isLoadingDelete"
                      :loading="isLoadingDelete"
                      >Delete</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <section v-if="itemsList && itemsList.length > 0">
              <v-data-table
                :headers="headers"
                :items="itemsList"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon class="mr-2" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                  <v-icon @click="editItem(item)"> mdi-pencil </v-icon>
                </template>
              </v-data-table>
            </section>
            <div class="loaderContainer" v-else-if="isLoading">
              <v-progress-circular
                :size="120"
                :width="4"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <EmptyState v-else></EmptyState>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import productEnum from "../../mixins/productEnums";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import EmptyState from "@/modules/shared/components/emptystate";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  mixins: [validationMixin, productEnum],
  validations: {
    formItem: {
      name: { required },
    },
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    isLoadingSave: false,
    isLoadingDelete: false,
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "HCP", sortable: false, value: "name" },
      { text: "ACTION", value: "actions", sortable: false, align: "right" },
    ],
    itemsList: [],
    editedIndex: -1,
    editedItem: null,
    formItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formItemNameErrors() {
      const errors = [];
      if (!this.$v.formItem.name.$dirty) return errors;
      !this.$v.formItem.name.required && errors.push("Item is required");
      return errors;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  components: {
    EmptyState,
  },
  methods: {
    initialize() {
      this.itemsList = [];
      this.isLoading = true;

      apiServices
        .get(`admin/product/${this.$route.params.id}/hcp`)
        .then((res) => {
          if (res) {
            this.isLoading = false;
            this.itemsList = res.data;
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.itemsList.indexOf(item);
      this.editedItem = item;
      this.formItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.itemsList.indexOf(item);
      this.editedItem = item;
      this.formItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.isLoadingDelete = true;
      apiServices
        .post(
          `admin/product/${this.$route.params.id}/edit-hcp/${this.editedItem.id}?name=${this.formItem.name}&is_active=0`
        )
        .then((res) => {
          if (res) {
            this.itemsList.splice(this.editedIndex, 1);
            this.isLoadingDelete = false;
            this.closeDelete();
          } else {
            this.isLoadingDelete = false;
            this.closeDelete();
          }
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      }, 100);
    },

    closeDelete() {
      this.dialogDelete = false;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      }, 100);
    },
    itemAddNew() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;

      apiServices
        .post(
          `admin/product/${this.$route.params.id}/add-hcp?name=${this.formItem.name}&is_active=1`
        )
        .then((res) => {
          if (res) {
            this.initialize();
            this.isLoadingSave = false;
            this.close();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;
      apiServices
        .post(
          `admin/product/${this.$route.params.id}/edit-hcp/${this.editedItem.id}?name=${this.formItem.name}&is_active=1`
        )
        .then((res) => {
          if (res) {
            console.log(res.data);
            this.initialize();
            this.isLoadingSave = false;
            this.close();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_hcp.scss";
</style>
